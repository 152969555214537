.App {
    text-align: center;
  }
  
  .exam-schedule {
    margin: 20px auto;
    width: 90%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .dropdown1{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }