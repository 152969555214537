

/* Style for input fields */
input[type="number"] {
    width: 70px; /* Adjust the width as needed */
  }
  
  /* Style for the table */
  .table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container th,
  .table-container td {
    border: 1px solid #ddd; /* Border style */
    padding: 8px; /* Padding around content */
    text-align: center; /* Align text to the left */
  }

 .table-container{
  height: 150%;
 }
 .form-btn1.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}