.form-group{
  width:100%; 
}
.form-control{
  width:100%;
}
.admission-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(238, 239, 245, 0.938);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}
 
form {
  display: grid;
  gap: 15px;
  width: 1170px;
}
.flex{
  display: flex;
  justify-content: space-between;
  width: 15%;
}
label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid rgb(199, 208, 240);
  border-radius: 4px;
}

input[type="date"] {
  padding: 8px;
}

.droplist {
  width: 100%;
  padding: 8px;
  border: 2px solid rgb(199, 208, 240);
  height: 50px;
  border-radius: 4px;
  margin-top: 5px;
}

.admission-form h2 {
  color: rgb(29, 29, 161);
  text-align: center;
  border: 2px solid rgb(197, 152, 152);
  border-radius: 10px;
  overflow: hidden;
  margin: 15px 0;
  padding: 10px;
}

.gender-label {
  display: flex;
  gap: 10px;
}

.gender-radio {
  display: flex;
  align-items: center;
}

.gender-radio input[type="radio"] {
  margin-right: 5px;
}

.custom-text-field .MuiInputLabel-root {
  color: black;
}

.custom-text-field .MuiInput-underline:before,
.custom-text-field .MuiInput-underline:after {
  border-bottom: none;
}

.custom-text-field .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: transparent;
}
.form-group1{
  width: 100%;
}
.form-group1 .form-dropdown {
  width: 100%;
  height: 43px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.uppercase {
  text-transform: uppercase;
}
.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust as needed for spacing between checkbox items */
}

.checkbox-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px; /* Adds padding around each checkbox item */
  border: 1px solid #ccc; /* Adds a border around each checkbox item */
  border-radius: 5px; /* Rounds the corners of the borders */
  background-color: #f9f9f9; /* Adds a light background color */
}

.checkbox-items label {
  flex-grow: 1;
  padding: 0;
  margin: 0;
}

.checkbox-items input[type="checkbox"] {
  margin-left: 10px; /* Adds space between the label and the checkbox */
  transform: scale(1.2); /* Makes the checkboxes slightly larger */
  height: 20px;
  width: 20px;
}
.checkbox-items input[type="checkbox"]:hover{
  cursor: pointer;
}

.form-group1 {
  margin-bottom: 20px; /* Adds space below the form group */
}

.form-group1 label {
  font-weight: bold; /* Makes the main label bold */
  margin-bottom: 10px; /* Adds space below the main label */
  display: block;
}

.checkbox-items:hover {
  background-color: #e9e9e9; /* Changes background color on hover */
}

.checkbox-items label::before {
  content: "📄"; /* Adds an icon before the label text */
  margin-right: 5px;
}
.modals {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  padding-top: 10px;
  padding-bottom: 5px;
}

.modal-contents {
  background-color: #fefefe;
  margin: 0 auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 210mm; 
  height: 297mm;
  overflow: auto; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.modal-contents::-webkit-scrollbar {
  display: none;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.print-buttons {
  display: flex;
  justify-content: space-between;
}
.print-section{
  padding: 0px 15px;
}
.printTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  /* margin-bottom: 20px; */
}

.school-logo {
  width: 90px;
  height: auto;
  margin-right: 20px;
}

.school-info2 {
  text-align: center;
  flex-grow: 1;
}

.school-info2 p {
  margin: 3px 0;
}

.school-info2 .p1 {
  font-size: larger;
  color: #2c3e50; /* Dark blue */
  font-weight: bold;
}

.school-info2 .p2 {
  font-size: 1.1rem;
  color: #2980b9; /* Bright blue */
}

.school-info2 .p3 {
  font-size: small;
  color: #7f8c8d; /* Gray */
}
.studentPhoto {
  width: 100px; 
  height: 110px; 
}
.AFA {
  border: 2px solid #2980b9; /* Bright blue border */
  border-radius: 10px; /* Rounded corners */
  padding: 5px 10px; /* Padding inside the border */
  font-size: 1rem; /* Larger font size */
  font-weight: bold; /* Bold text */
  text-align: center; /* Centered text */
  color: #2c3e50; /* Dark blue text color */
  background-color: #ecf0f1; /* Light gray background */
  margin-bottom: 5px; /* Space below the element */
}
.admission-details {
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 210mm; /* A4 width */
  margin: 0 auto;
  box-sizing: border-box;
  display: flex; 
  justify-content: space-between;
  flex-direction: column;
}

.admission-details p {
  font-size: 14px;
  margin: 8px 0;
}

.admission-details p strong {
  color: #333;
}

.admission-details ul {
  list-style-type: none;
  padding: 0; 
}

.admission-details ul li {
  font-size: 14px;
  /* margin: 8px 0; */
}

.admission-details h4 {
  color: #0056b3;
}

.admission-details p,
.admission-details ul li {
  border-radius: 4px;
}

@media print {
  .admission-details {
    page-break-after: always;
  }
}
.formCorrect{
  display: flex;
  justify-content: flex-start;
}
.formCorrect p{
  width: 50%;
}
.formCorrect li{
  width: 50%;
}
.sign {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.sign li {
  list-style-type: none; /* Remove default list styling */
  font-size: 12px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Adjust margin between list items */
}
  