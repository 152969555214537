.carousel-item img {
  max-height: 90vh; /* Adjust the height according to your needs */
  object-fit: cover;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better text readability */
  padding: 20px; /* Adds padding around the text */
  border-radius: 10px; /* Rounded corners for the caption */
}

.carousel-caption h3 {
  font-size: 2rem; /* Larger font size for the heading */
  color: #fff; /* White color for the heading text */
  margin-bottom: 10px; /* Space below the heading */
}

.carousel-caption p {
  font-size: 1.2rem; /* Slightly larger font size for the paragraph */
  color: #ddd; /* Light grey color for the paragraph text */
}

.carousel-indicators li {
  background-color: #000; /* Black color for the indicator dots */
}

.carousel-indicators .active {
  background-color: #fff; /* White color for the active indicator dot */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #000; /* Black background for the control icons */
  border-radius: 50%; /* Rounded control icons */
  padding: 10px; /* Adds padding to the control icons */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: #fff; /* White background on hover */
  color: #000; /* Black color for the icon itself */
}
