/* Add this CSS to your stylesheets */

/* General styling */
.dashboard-content {
    padding: 20px;
  }
  
  .topOption1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#bfcef3b6;
    padding: 10px;
    border-radius: 10px;
  }
  
  /* Search bar styling */

  
  .searchBar input {
    border: none;
    padding: 8px;
    border-radius: 5px;
    outline: none;
  }
  
  /* Button styling */
  .newTeacher button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-bottom: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .newTeacher button:hover {
    background-color: #45a049;
  }

.AllCards {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.AllCards::-webkit-scrollbar { 
  width: 10px;
}

.AllCards::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AllCards::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

.AllCards .containerCard {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em;
  background-color: #e0e4eeb6;
  margin: 0 -30px;
  border-radius: 10px;
}

.AllCards .card {
  position: relative;
  width: 260px;
  height: 380px;
  margin: 1em;
  background: rgba(202, 47, 119, 0.212);
  box-shadow: 0 15px 35px rgba(0, 0, 0, .2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(40px);
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: 0px 10px 10px rgba(46, 54, 68, 0.03);
}

.AllCards .card .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
  transition: 0.5s;
}

.AllCards .card .content .img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid rgba(131, 13, 241, 0.514);
}

.AllCards .card .content .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AllCards .card .content .cardContent h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 10px;
  line-height: 1.1em;
}

.AllCards .card .content .cardContent h3 span {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

.AllCards .card .sci {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  margin-right: 26px;
}

.AllCards .card .sci li {
  margin: 0 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: 0.5s;
  transition-delay: calc(0.1s * var(--i));
  list-style-type: none;
}

.AllCards .card .sci li a {
  font-size: 24px;
}

.AllCards .card:hover .content {
  opacity: 1;
  transform: translateY(-20px);
}

.AllCards .card:hover .sci li {
  transform: translateY(0px);
  opacity: 1;
}

.lds-roller {
  color: #1c4c5b;
}
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto; /* Center the spinner */
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}