.table-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.table-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table-container th,
.table-container td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.table-container th {
    background-color: #007bff;
    color: white;
}

.table-container tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table-container tr:hover {
    background-color: #e9e9e9;
}

.table-container th,
.table-container td {
    text-align: center;
}
.date-filter{
    display: flex;
    justify-content: space-around;
}