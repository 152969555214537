.DriverList {
  display: flex;
  align-items: center;
  width: 50%;
}

.allStud {
  padding: 0 55px;
}

.stud-list {
  min-height: 400px;
}

.tableStudentMarks {
  max-height: 500px; /* Adjusted height for a larger scrollable area */
  overflow-y: auto;
  border: 1px solid #ddd; /* Added border around the table container */
  border-radius: 8px; /* Added border-radius for rounded corners */
  background-color: #fafafa; /* Light background color for better contrast */
}

.stud-list {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed for better table structure */
}

.stud-list thead, .stud-list tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.stud-list thead {
  background-color: #f5f5f5; /* Background color for the header */
}

.stud-list tbody {
  display: block;
  height: 390px; /* Increased height for better readability */
  overflow-y: auto;
}

.stud-list tbody:empty::before {
  content: '';
  display: table-row-group;
  height: calc(390px - (6 * 40px)); /* Adjusted height to fill the space */
}

.stud-list th, .stud-list td {
  text-align: center;
  border: 1px solid #ddd; 
}

.stud-list th {
  font-weight: bold; 
  background-color: #e0e0e0; 
}

.stud-list td {
  background-color: #fff; 
}

.stud-list tr:nth-child(even) {
  background-color: #f9f9f9; 
}

.stud-list tbody tr {
  min-height: 30px; 
}