.chatlist__item {
    display: flex;
    align-items: center; /* Align items vertically */
    border-bottom: 1px solid #ebe7fb;
    padding: 10px; /* Adjust padding as needed */
    margin-top: 10px;
    cursor: pointer;

    transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  }
  
  .chatlist__item:first-child {
    margin-top: 0;
  }
  
  /* Avatar styles */
  .avatar {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    margin-right: 10px; /* Adjust spacing between avatar and content */
  }
  
  /* User meta styles */
  .userMeta p {
    margin: 0;
    padding: 0;
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }
  
  .userMeta span {
    margin: 0;
    padding: 0;
    color: #ceccd3;
    font-weight: 400;
    font-size: 12px;
  }
  
  /* Hover and active styles */
  .chatlist__item:hover,
  .chatlist__item.active {
    background: #fff;
    border-radius: 10px;
  }