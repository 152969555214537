.topOption {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dropdown2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: rgba(216, 216, 255, 0.507);
  padding: 10px 0;
  margin: 0 10px;
}

.dropdown2 select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.teacher-dashboard {
  margin: 20px 10px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.teacher-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.teacher-photo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 20px;
}

.teacher-details {
  max-width: 400px;
}

.teacher-details h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 5px;
}

.teacher-details p {
  color: #555;
  margin-bottom: 10px;
}

.teacher-body {
  margin-top: 20px;
}

.teacher-section {
  margin-bottom: 20px;
}

.teacher-section h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.teacher-section p {
  color: #555;
  font-size: 16px;
}

.teacher-section ul {
  list-style: none;
  padding: 0;
  margin-top: 5px;
}

.teacher-section li {
  color: #777;
}

h3 span {
  color: #777;
  font-weight: normal;
}

.container_Bottom_data {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.Lastcard {
  text-align: center;
}

.Lastcard .details {
  margin-top: 5px;
  color: #007bff;
  cursor: pointer;
}

.Lastcard .details:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .teacher-header {
    flex-direction: column;
    align-items: center;
  }

  .teacher-photo {
    margin-bottom: 20px;
  }

  .teacher-details {
    max-width: none;
    text-align: center;
  }

  .container_Bottom_data {
    flex-direction: column;
    align-items: center;
  }

  .Lastcard {
    margin-bottom: 20px;
  }
}
.teacher-section p{
  margin-left: 70px;
}