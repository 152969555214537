.main-container {
  display: flex;
  background-color: #4D44B5;
}

.sidebar {
  color: white;
  box-sizing: border-box;
  transition: width 0.5s ease;
  cursor: pointer;  
}
.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}
.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: -65px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  background: #F3F4FF;
  color:#4D44B5;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link.active {
  background: #F3F4FF;
  color: #4D44B5;
}

/* Add this style to remove the active class from other links when a link is clicked */
.link.clicked {
  background: #F3F4FF;
  color: #4D44B5;
}
.link_text {
  white-space: nowrap;
  font-size: 19px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 15px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
  overflow: hidden;
}
.menu_item {
  display: flex;
  gap: 9px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 21px;
}
