.avatar {
    position: relative;
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    border-radius: 50%;
    background-color: #ffffff; /* Background color of the avatar */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 2px solid #ffffff; /* Border color and width */
  }
  
  .avatar-icon {
    width: 100%; /* Ensure the icon fills the avatar container */
    height: 100%;
  }
  
  .isOnline {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px; /* Adjust as needed */
    height: 10px; /* Adjust as needed */
    border-radius: 50%;
  }
  
  .isOnline.active {
    background-color: #75db76; /* Online indicator color */
  }
  
  .isOnline.inactive {
    background-color: #dcdcdc; /* Offline indicator color */
  }