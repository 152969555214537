/* StudentPromotionForm.css */
.promotion-form {
    margin: 0 20%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .promotion-form h2{
    color: rgb(29, 29, 161);
    text-align: center;
    border: 2px solid rgb(197, 152, 152);
    border-radius: 10px;
    overflow-y: hidden;
    margin: 10px 60px;
    padding: 2px;
 }
  form {
    display: grid;
    gap: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
.promotAll{
    background-color: rgb(155, 71, 230);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0px;
}
.promotAll:hover{
  background-color: rgb(111, 60, 156);
}
.or{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}
.modal {
  display: block; /* Ensure modal is displayed */
  position: fixed; /* Position the modal */
  z-index: 1; /* Set z-index to make it appear above other content */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Enable scrolling if content exceeds viewport height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
}

.modal-content {
  background-color: #fefefe; /* Modal background color */
  margin: 15% auto; /* Center modal vertically and horizontally */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Set width of modal */
  max-width: 400px; /* Maximum width of modal */
  overflow: hidden;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.iframe{
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.mail{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
