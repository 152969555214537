.main__chatlist {
  border-right: 1px solid #ebe7fb;
  margin: 9px;
  display: flex;
  flex-direction: column;
  height: 77vh;
}

.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  border-bottom: 1px solid #ebe7fb;
  overflow: hidden;
}

.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #dad9dd;
  outline: none;
}

.search_wrap {
  background-color: #e6e5ea;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 5px 0;
  overflow: hidden;
}

.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 10px;
  outline: none;
  width: 100%;
}

.search-btn {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  color: #dad9dd;
}

.chatlist__items {
  margin-top: 10px;
  overflow: auto;
  flex-grow: 1;
  max-height: calc(100vh - calc(100vh / 2.6));
}

.studchat {
  overflow-y: hidden;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.chatlist__item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.chatList__search{
  overflow: hidden;
}
.chatlist__item:hover {
  background-color: #f1f1f1;
}

.chatlist__item.active {
  background-color: #ebe7fb;
}

.chatlist__item .avatar {
  margin-right: 10px;
}

.chatlist__item .info {
  display: flex;
  flex-direction: column;
}

.chatlist__item .info .name {
  font-weight: 500;
}

.chatlist__item .info .status {
  font-size: 12px;
  color: #909090;
}