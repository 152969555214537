.small-browser-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(55, 56, 71, 0.973); /* Background with opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .message-container {
    text-align: center;
    color: white;
  }
  
  .message {
    margin-top: 20px;
  }
  
  .fullscreen-icon {
    font-size: 40px;
  }
  
  /* Hide the message by default */
  .small-browser-message.hidden {
    display: none;
  }
  
  /* Show the message when the browser window is too small */
  .small-browser-message.visible {
    display: flex;
  }
  