.exam-schedule-container {
    margin: 20px auto;
    width: 100%;    
    overflow: hidden;
  }
  
  /* Background color for the form section */
  .exam-form {
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Choose your desired color */
    padding: 20px;
    border-radius: 8px;
  }
  
  .form-header {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .form-group {
    margin-right: 10px;
  }
  
  .label {
    margin-right: 5px;
  }
  
  .input-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;

  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  /* Background color for the table section */
  .exam-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #e5e5e5; /* Choose your desired color */
    border-radius: 8px;
    overflow: hidden; /* Ensures rounded corners are applied */
  }
  
  .exam-table th,
  .exam-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .exam-table th {
    background-color: #f2f2f2;
  }
  .tableFooter{
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
  }
  .exam-name{
    display: flex;
  }
  .headerAddNewExam{
    width: 100%;
  }